import React, { useState } from 'react';
import styles from './NavBar.module.scss';
import NavBarLogo from './NavBarLogo';
// import NavBarMenu from './NavBarMenu';
// import HeaderNavbarAligner from './HeaderNavbarAligner';
// import NavBarLogin from './NavBarLogin';
import Container from '../AppContainer/Container';
import queryString from 'query-string';
import {
  BLOG_LISTING_PAGE,
  CATEGORY_BASE,
  CAUSE_CREATE_ROUTE,
  CONTENT_PAGE,
  CONTENT_PAGE_BASE,
  LOGIN_ROUTE,
  ORGANIZATION_PROFILE_IMPACT_ROUTE,
  PROFILE_IMPACT_ROUTE,
} from '../../routes';
import { Link } from 'react-router-dom';
// import NavBarCategories from './NavBarCategories';
import NavBarLayerWrapper from './NavBarLayerWrapper';
import NavBarLayer from './NavBarLayer';
import NavBarLayerItem from './NavBarLayerItem';
import { connect } from 'react-redux';
import NavBarUserMenu from './NavBarUserMenu';
import NavBarMobileLangSwitch from './NavBarMobileLangSwitch';
import translate from '../../lang';

// interface NavBarPropType {
//   user: any; // USERType
// }

const NavBar /*: React.FC*/ = (props /*: NavBarPropType*/) => {
  const { user } = props;
  const [forceShowNavigation, setForceShowNavigation] = useState(false);

  // define the user type
  let userType /*: 'guest' | 'ngo' | 'user'*/ = 'guest';
  if (user && !user.guest) {
    userType = user.organization ? 'ngo' : 'user';
  }

  // define some special links
  let myImpactRoute = LOGIN_ROUTE;
  switch (userType) {
    case 'ngo':
      myImpactRoute = ORGANIZATION_PROFILE_IMPACT_ROUTE;
      break;
    case 'user':
      myImpactRoute = PROFILE_IMPACT_ROUTE;
      break;
  }

  const closeLayer = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 990) {
      setForceShowNavigation(!forceShowNavigation);
    }
  };
  const queryParams = queryString.parse(window?.location?.search);
  const noshell = (typeof queryParams.noshell && queryParams.noshell === 'true');


  // if shell should not be visible -> just display null
  if (noshell) {
    return null;
  }

  return (
    <>
      <div className={styles.NavBarPlaceholder} />
      <div className={styles.NavBar}>
        <Container>
          <div
            className={`${styles.NavBarInner} ${
              forceShowNavigation ? styles.ForceShow : ''
            }`}
          >
            <div className={styles.NavBarLangSwitchMobile}>
              <NavBarMobileLangSwitch />
            </div>

            <NavBarLogo />

            <button
              type="button"
              onClick={closeLayer}
              className={styles.NavBarMenu}
            >
              <div className={styles.MenuTitle}>
                {translate('menu', 'openmenu')}
              </div>
              <div className={styles.MenuTitleClose}>
                {translate('menu', 'closemenu')}
              </div>

              <div className={styles.BurgerMenuIcon}>
                <div />
              </div>
            </button>

            <div className={styles.NavBarMobileWrapper}>

              <div className={styles.NavBarInfo}>
                <div className={styles.NavBarInfoLink}>
                  <NavBarLayerWrapper
                    mainLinkLabel={translate('menu', 'donate')}
                    mainLinkTo={CATEGORY_BASE}
                    mainLinkClassName={`${styles.NavBarInfoLinkItem} ${styles.NavBarInfoLinkItemFirst}`}
                    closeLayer={closeLayer}
                  >
                    <NavBarLayer>
                      <NavBarLayerItem
                        to={`${CATEGORY_BASE}`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'donate.causes')}
                      </NavBarLayerItem>

                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/spendenkompass`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'donation.compass')}
                      </NavBarLayerItem>

                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/Organization`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'organizations')}
                      </NavBarLayerItem>

                      <NavBarLayerItem
                        href="https://gutschein.impactory.org/privatpersonen"
                        onClick={closeLayer}
                      >
                        {translate('menu', 'donation.gift.cards')}
                      </NavBarLayerItem>
                    </NavBarLayer>
                    {/*
                      It fetches menu items from API.
                      Uncomment it and remove other siblings of this component, if we use the API menu items again
                      <NavBarCategories />
                    */}
                  </NavBarLayerWrapper>
                </div>

                <div className={styles.NavBarInfoLink}>
                  <NavBarLayerWrapper>
                    <Link
                      to={`${BLOG_LISTING_PAGE}`}
                      className={`${styles.NavBarInfoLinkItem}`}
                      onClick={closeLayer}
                    >
                      {translate('menu', 'stories')}
                    </Link>
                    <NavBarLayer>
                      <NavBarLayerItem
                        to={`${BLOG_LISTING_PAGE}`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'blog')}
                      </NavBarLayerItem>
                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/Schwerpunkte`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'focus.topics')}
                      </NavBarLayerItem>
                    </NavBarLayer>
                  </NavBarLayerWrapper>
                </div>

                <div className={styles.NavBarInfoLink}>
                  <NavBarLayerWrapper>
                    <Link
                      to={`${CONTENT_PAGE_BASE}/uber-uns`}
                      className={`${styles.NavBarInfoLinkItem}`}
                      onClick={closeLayer}
                    >
                      {translate('menu', 'aboutus')}
                    </Link>

                    <NavBarLayer>
                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/uber-uns`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'aboutimpactory')}
                      </NavBarLayerItem>
                      <NavBarLayerItem
                        to="/introduction/user"
                        onClick={closeLayer}
                      >
                        {translate('menu', 'whyimpactory')}
                      </NavBarLayerItem>
                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/howitworks`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'howitworks')}
                      </NavBarLayerItem>
                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/unsere-partner`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'partner')}
                      </NavBarLayerItem>
                    </NavBarLayer>
                  </NavBarLayerWrapper>
                </div>
              </div>

              <div className={styles.NavBarLogoPlaceholder} />

              <div className={styles.NavBarInfo}>
                <div className={styles.NavBarInfoLink}>
                  <NavBarLayerWrapper>
                    <Link
                      to={`${CONTENT_PAGE_BASE}/unternehmen`}
                      className={`${styles.NavBarInfoLinkItem}`}
                      onClick={closeLayer}
                    >
                      {translate('menu', 'corporate')}
                    </Link>

                    <NavBarLayer>
                      <NavBarLayerItem
                        to={`${CONTENT_PAGE_BASE}/impactpartner`}
                        onClick={closeLayer}
                      >
                        {translate('menu', 'impactpartner')}
                      </NavBarLayerItem>
                      <NavBarLayerItem
                        href="https://gutschein.impactory.org"
                        onClick={closeLayer}
                      >
                        {translate('menu', 'donation.gift.cards')}
                      </NavBarLayerItem>
                    </NavBarLayer>
                  </NavBarLayerWrapper>

                  <NavBarLayerWrapper position="right">
                    <Link
                      to={myImpactRoute}
                      className={`${styles.NavBarInfoLinkItem} ${styles.NavBarInfoLinkItemLast}`}
                      onClick={closeLayer}
                    >
                      {translate('menu', 'myimpactarea')}
                    </Link>

                    <NavBarUserMenu userType={userType} onClick={closeLayer} />
                  </NavBarLayerWrapper>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { user } = state.app;
  return {
    user,
  };
};

export default connect(mapStateToProps)(NavBar);
